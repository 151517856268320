<template>
  <div>
    <b-row>
      <b-col class="margin-col-first -underline">
        <div
        >
          <label class="title">{{ $t('admin.clubs.import.steps.step0.title-content')}}</label>
        </div>
      </b-col>
    </b-row>
    <div class="upload-container mt-4" @dragover="handleDragOver" @dragenter="handleDragEnter" @dragleave="handleDragLeave" @drop="handleDrop">
      <div class="shadow-background" v-if="isDragging">
        <label class="text-light-gray font-weight-bold">{{ $t('admin.clubs.import.steps.step0.drop-file') }}</label>
      </div>
      <div  class="pointer" @click="uploadFile" v-else>
        <b-row class="d-flex flex-column text-center">
          <b-col>
            <i class="fa fa-cloud-upload d-icon-custom"></i>
          </b-col>
          <b-col class="d-flex flex-column text-center">
            <label class="text-grey-custom font-weight-bold mb-0">{{ $t('admin.clubs.import.steps.step0.description-content')}}</label>
            <label class="text-red-custom font-weight-bold">{{ $t('admin.clubs.import.steps.step0.csv-required')}}</label>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step0",
  data: () => ({
    isDragging: false
  }),
  methods: {
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
    },
    handleDragEnter() {
      this.isDragging = true;
    },
    handleDragLeave() {
     this.isDragging = false;
    },
    handleDrop(event) {
      event.preventDefault();
      this.$emit('on:upload-file', event);
      this.isDragging = false;
    },
    upload(file) {
      this.$emit('on:upload-file', file);
    },
    uploadFile() {
      this.$upload(this.upload,{
        entry: '',
        url: null,
        target: ''
      } )
    }
  },
}

</script>

<style lang="scss" scoped>
@import "@lazy/import/_step.scss";

.d-icon-custom {
  font-size: 100px;
}

.shadow-background {
  background-color: #0B2772;
  box-shadow: 0 2px 4px rgba(31, 31, 31, 0.1);
  padding: 20px;
  border-radius: 10px;
}

</style>
