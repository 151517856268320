import {_get, _post} from "@api/services/httpService";

export const URL = 'clubs/client-imports';

export const postImportClient = (data) => {
  return _post(URL, data);
}

export const getImportClient = (id) => {
  return _get(URL + '/' + id);
}
