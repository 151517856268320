<template>
  <div
    class="container-fluid"
  >
    <div v-if="isBusy">
      <div class="d-flex justify-content-center align-items-center spinner-busy mt-5">
        <b-spinner
          variant="blue-light-spinner"
          type="grow"></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-row cols="12" class="mt-4 justify-content-center" v-if="!importInProgress">
        <div slot="icons">
          <steps
            :step="step"
            @on:step-0:selected="newImport"
            @on:step-1:selected="step = 1"
            @on:step-2:selected="step = 2"
            @on:step-3:selected="step = 3"
          >
          </steps>
        </div>
      </b-row>

      <div class="mt-4">
        <step0
          v-if="step === 0"
          @on:upload-file="onUploadFile"
        />
        <step1
          v-if="step === 1"
          :file="file"
          @on:reset-import-file="newImport"
          @on:validate="step = 2"

        />
        <step2
          v-if="step === 2"
          :file="file"
          @on:validate="postImportClient"
        />
        <step3
          v-if="step === 3"
          :file="file"
          :status="statusImport"
          @on:reload-import-status="onReloadImportStatus"
          @on:new-import="onFinishedImport"
        />
      </div>
    </div>

  </div>
</template>

<script>

import Step0 from "@custom/clubs/import/step/Step0";
import {DANGER} from "@plugins/flash";
import Step1 from "@custom/clubs/import/step/Step1";
import Step2 from "@custom/clubs/import/step/Step2";
import Step3 from "@custom/clubs/import/step/Step3";
import {fromIdToIriReference, fromIriReferenceToId} from "@/utils/form";
import {getImportClient, postImportClient} from "@api/services/client-import/client-import.api";
import ClientImports from "@/classes/ClientImports";

export default {
  name: "Import",
  components: {
    Step3,
    Step2,
    Step1,
    Step0,
    Steps: () => import('@custom/clubs/import/Menu'),
  },
  data() {
    return {
      isBusy: false,
      step: 0,
      file : null,
      importInProgress: false,
      statusImport: ''
    }
  },
  computed: {
    getImportId() {
      return localStorage.getItem('idImport');
    }
  },
  methods: {
    onFinishedImport() {
      this.statusImport = '';
      localStorage.removeItem('idImport');
      this.newImport();
    },
    onReloadImportStatus() {
      this.isBusy = true;
      this.getImportStatus();
    },
    getImportStatus(idImport = null) {
      return new Promise((resolve, reject) => {
        this.statusImport = null;

        getImportClient(idImport ? idImport : this.getImportId)
          .then((response) => {
            const clientImport = new ClientImports(response.data, {serialize: true});

            this.statusImport = clientImport.status;
            resolve();
          })
          .finally(() => {
            this.isBusy = false;
            idImport = null;
          })
      });
    },
    onImportClientFile(idImport) {
      this.isBusy = true;
      this.getImportStatus(idImport)
        .then(() => {
          this.step = 3;
        })
        .finally(() => {
          this.isBusy = false;
        })
    },
    postImportClientFile(data) {
      postImportClient({
        file: data["@id"],
        club: fromIdToIriReference('/clubs', localStorage.getItem('selectedClub'))
      })
        .then((response) => {
          const idImport = fromIriReferenceToId('/clubs/client-imports/', response.data["@id"]);
          localStorage.setItem('idImport', idImport);
          this.importInProgress = true;
          this.onImportClientFile(idImport);
        })
        .catch(() => {
          this.$flash(null, this.$t('admin.clubs.import.steps.import-error'), 5000, DANGER);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    postImportClient(){
      const payload = {
        entry: 'clubClientImport',
        url: '',
        target: 'postCsvFile',
      };

      this.isBusy = true;

      this.$uploadFile(this.file.fileInfo.file, payload)
        .then((response) => {
          this.postImportClientFile(response);
        })
      ;
    },
    parseCSV(file, callback) {
      const reader = new FileReader();
      const fileInfo = {
        file: file,
        lines: 0,
        headers: null
      }

      reader.onload = (event) => {
        const csvData = event.target.result;
        const lines = csvData.split('\n');
        const headers = lines[0].split(';');

        fileInfo.lines = lines.length;
        fileInfo.headers = headers;

        const data = [];
        const dataError = [];

        for (let i = 1; i < lines.length; i++) {
          const values = lines[i].split(';');

          if(values.length !== headers.length) {
            const error = {
              values : values,
              line: i + 1,
              errorType: this.$t('admin.clubs.import.steps.step0.error.column-error')
            };

            dataError.push(error);
          }else if(values[0].trim() === '') {
            const error = {
              values : values,
              line: i + 1,
              errorType: this.$t('admin.clubs.import.steps.step0.error.id-column-error')
            };

            dataError.push(error);
          } else {
            const entry = {};
            for (let j = 0; j < headers.length; j++) {
              entry[headers[j]] = values[j];
            }
            data.push(entry);
          }
        }

        callback({data, dataError, fileInfo});
      };

      reader.readAsText(file, 'UTF-8');
    },
    verifyCSVData(file) {
      this.isBusy = true;

      return new Promise((resolve, reject) => {
        this.parseCSV(file, resolve)
      });
    },
    verifyCSVFile(file) {
      return new Promise((resolve, reject) => {
        const verify = { isValid: false, message: '' };

        if(file.length > 1){
          verify.message = this.$t('admin.clubs.import.steps.step0.error.one-file-upload');
          reject(verify);
        }

        if (file.type !== 'text/csv') {
          verify.message = this.$t('admin.clubs.import.steps.step0.error.not-csv-file');
          reject(verify);
        }

        this.verifyCSVData(file)
          .then((results) => {
            verify.isValid = true;
            resolve(results);
          })
          .finally(() => {
            this.isBusy = false;
          })
      });

    },
    onUploadFile(event) {
      const file = event.dataTransfer ? event.dataTransfer.files : event;
      this.file = null;

      this.verifyCSVFile(file)
        .then((fileData) => {
          this.file = fileData;
          this.step = 1;
        })
        .catch((verify) => {
          this.$flash(null, verify.message , 5000, DANGER);
        })
    },
    resetImport() {
      this.file = null;
    },
    newImport() {
      this.resetImport();
      this.step = 0;
    }
  },
  mounted() {
    if(this.getImportId) {
      this.isBusy = true;
      this.getImportStatus()
        .then(() => {
          this.importInProgress = true;
          this.step = 3;
        });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
