
import { hydrate } from "@/utils/form";

export default class ClientImports {
  subscriptionPlanPriceMatch = null;
  paymentTokenMatch = null;
  file = null;
  club = null;
  status = null;
  updatedAt = null;
  createdAt = null;
  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object)
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

}
