<template>
  <div v-if="status">
    <b-row>
      <b-col class="margin-col-first">
        <div
        >
          <label class="title">{{ $t('admin.clubs.import.steps.step3.title-content')}}</label>
        </div>
      </b-col>
    </b-row>
    <div>
      <b-row class="p-3 mt-4 mb-4" :class="'header-import-' + status">
        <b-row cols="12">
          <b-col class="d-flex flex-column">
            <label class="font-weight-bold mb-0"> {{ getStatus.headerImport }}</label>
          </b-col>
        </b-row>
      </b-row>
      <b-row>
        <label class="font-weight-bolder"> {{ $t('admin.clubs.import.steps.step3.state') }} </label>
        <label class="ml-2 font-weight-light"> {{ getStatus.label }} </label>
        <i class="ml-1 fa fa-circle circle-size" :class="status" />
      </b-row>
      <b-row class="d-flex justify-content-center">
        <d-button
          v-if="isFinshed"
          :text="'admin.clubs.import.steps.step3.new-import'"
          :class="'d-btn btn d-btn-primary'"
          @on:button-click="$emit('on:new-import')"
        />
        <d-button
          v-else
          :text="'admin.clubs.import.steps.step3.reload'"
          :class="'d-btn btn d-btn-primary mr-4'"
          @on:button-click="$emit('on:reload-import-status')"
        />
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "Step3",
  data() {
    return {
      statusLabel: '',
      headerImport: '',
      isFinshed: false
    }
  },
  props: {
    file: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },
  computed: {
    getStatus() {
      this.headerImport = this.$t('admin.clubs.import.steps.step3.import-loading');

      switch (this.status){
        case 'pending':
          this.statusLabel = this.$t('admin.clubs.import.steps.step3.status.pending');
          break;
        case 'in_progress':
          this.statusLabel = this.$t('admin.clubs.import.steps.step3.status.in_progress');
          break;
        case 'failed':
          this.headerImport = this.$t('admin.clubs.import.steps.step3.import-failed');
          this.statusLabel = this.$t('admin.clubs.import.steps.step3.status.failed');
          break;
        case 'ended':
          this.headerImport = this.$t('admin.clubs.import.steps.step3.import-ended');
          this.statusLabel = this.$t('admin.clubs.import.steps.step3.status.ended');
          break;
      }

      return { label: this.statusLabel, headerImport: this.headerImport };
    }
  },
  mounted() {
    if(this.status === 'failed' || this.status === 'ended') {
      this.isFinshed = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/import/_step.scss";
</style>
