<template>
  <div>
    <b-row>
      <b-col class="margin-col-first">
        <div>
          <label class="title">{{ $t('admin.clubs.import.steps.step2.title-content') }}</label>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-4">
      <div class="col">
        <label class="title">{{ $t('admin.clubs.import.steps.step2.your-subscriptions') }}</label>
        <d-table
          :tablefields="subscriptionTableFields"
          :items="subscriptions"
          :is-busy="isSubscriptionsBusy"
          :show-pagination="false"
          :per-page="subscriptions.length"
        />
      </div>
      <div class="col">
        <label class="title">{{ $t('admin.clubs.import.steps.step2.your-credits') }}</label>
        <d-table
          :tablefields="paymentTokenTableFields"
          :items="paymentToken"
          :is-busy="isPaymentTokenBusy"
          @on:select-option:change="test"
          :show-pagination="false"
        />
      </div>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <d-button
        :text="'admin.clubs.import.steps.step2.validate'"
        :class="'d-btn btn d-btn-primary'"
        @on:button-click="$emit('on:validate')"
      />
    </b-row>
  </div>
</template>

<script>
import {getSubscriptionPlans} from "@api/services/subscription-plans/subscription-plans.api";
import {
  getSubscriptionPlanPrices
} from "@api/services/subscription-plans/subscription-plan-price/subscription-plan-price";
import {getPaymentToken} from "@api/services/payment-token/payment-token.api";

export default {
  name: "Step2",
  data: () => ({
    paymentToken: [],
    paymentTokenSelect: [],
    isPaymentTokenBusy: false,
    subscriptions: [],
    isSubscriptionsBusy: false,
    subscriptionPlan: []
  }),
  computed: {
    paymentTokenTableFields() {
      return [
        { key: 'name', label: this.$t('admin.clubs.import.steps.step2.payment-token') },
        { key: 'select', label: this.$t('admin.clubs.import.steps.step2.payment-token-type') }
      ]
    },
    subscriptionTableFields() {
      return [
        {
          key: 'name', label: this.$t('admin.clubs.import.steps.step2.subscription-name')
        },
        {
          key: 'select', label: this.$t('admin.clubs.import.steps.step2.subscription-price')
        }
      ]
    }
  },
  props: {
    file: {
      type: Object,
      default: null,
    }
  },
  methods: {
    test(scope) {
      console.log(scope, 'my scop')
    },
    loadSubscriptionPlan() {
      this.isSubscriptionsBusy = true;
      getSubscriptionPlans(localStorage.getItem('selectedClub'), true)
        .then((response) => {
          for (const elt of response.data['hydra:member']) {
            this.subscriptionPlan.push({text: elt.name, value: elt.id});
          }
          this.file.data.forEach((obj) => {
            const subscription = obj.subscription_card_plan_price_id;

            if (subscription && !this.subscriptions.some(item => item.subscription_card_plan_price_id === subscription)) {
              this.subscriptions.push({
                name: subscription,
                select: this.subscriptionPlan
              });
            }
          })
        })
        .finally(() => {
          this.isSubscriptionsBusy = false;
        })
    },
    loadClubPaymentToken() {
      this.isPaymentTokenBusy = true;

      getPaymentToken(localStorage.getItem('selectedClub'))
        .then((response) => {
          for (const elt of response.data['hydra:member']) {
            this.paymentTokenSelect.push({text: elt.name, value: elt.id});
          }
          this.file.data.forEach((obj) => {
            const payment = obj.payment_token_id;
            if (payment && !this.paymentToken.some(item => item.name === payment)) {
              this.paymentToken.push({
                name: payment,
                select: this.paymentTokenSelect
              });
            }
          })
        })
        .finally(() => {
          this.isPaymentTokenBusy = false;
        })

    }
  },
  mounted() {
    this.loadClubPaymentToken();
  }
}

</script>

<style lang="scss" scoped>
@import "@lazy/import/_step.scss";
</style>
