<template>
  <div>
    <b-row>
      <b-col class="margin-col-first">
        <div
        >
          <label class="title">{{ $t('admin.clubs.import.steps.step1.title-content') }}</label>
        </div>
      </b-col>
    </b-row>
    <div class="p-3" :class=" isValidFile ? 'header-upload-success' : 'header-import-failed'">
      <b-row cols="12">
        <b-col cols="8" class="d-flex flex-column">
          <label class="font-weight-bold mb-0"> {{ $t('admin.clubs.import.steps.step1.upload-success') }}</label>
          <label class="font-weight-lighter"> {{ file.fileInfo.file.name }} </label>
          <label v-if="!isValidFile" class="font-weight-bold mb-0"> {{ $t('admin.clubs.import.steps.step1.upload-fail') }}</label>
        </b-col>
        <b-col @click="$emit('on:reset-import-file')" class="d-flex justify-content-end align-items-center">
          <label class="update-import"> {{ $t('admin.clubs.import.steps.step1.update-file-upload') }}</label>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-4">
      <d-table
        :tablefields="tableFields"
        :items="items"
        :is-busy="isBusy"
        :totalRows="totalRows"
        :per-page="itemsPerPage"
        :show-pagination="false"
      />
    </b-row>
    <b-row v-if="isValidFile" class="d-flex justify-content-center">
      <d-button
        :text="'admin.clubs.import.steps.step2.confirmed'"
        :class="'d-btn btn d-btn-primary'"
        @on:button-click="$emit('on:validate')"
      />
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Step1",
  data: () => ({
    isDragging: false,
    isBusy: false,
    totalRows: 0,
    itemsPerPage: 50,
    tableFields: [],
    items: []
  }),
  props: {
    file: {
      type: Object,
      default: null
    }
  },
  computed: {
    isValidFile() {
      return !this.file.dataError.length > 0;
    },
    uploadFileTitle() {
      return this.$t('admin.clubs.import.steps.step1.upload-file-title-1') +
        this.file.fileInfo.file.name +
        this.$t('admin.clubs.import.steps.step1.upload-file-title-2') +
        this.file.fileInfo.lines +
        this.$t('admin.clubs.import.steps.step1.lines-and') +
        this.file.fileInfo.column +
        this.$t('admin.clubs.import.steps.step1.column')
    }
  },
  methods: {
    createTableFields() {
      return new Promise((resolve, reject) => {
        this.isBusy = true;

        if(this.file.dataError.length > 0) {
          const lineField = {key: 'line', label: 'Ligne'};
          const typeErrorField = {key: 'errorType', label: 'Type d\'erreur'};

          this.tableFields.push(lineField);
          this.tableFields.push(typeErrorField);
          resolve(this.file.dataError);
        } else {
          this.file.fileInfo.headers.forEach((column) => {
            const fields = {key: column, label: column};

            this.tableFields.push(fields);
          })
          resolve(this.file.data);
        }
      })
    }
  },
  mounted() {
    this.createTableFields()
      .then((data) => {
        this.items = data;
        this.totalRows = data.length;
        this.isBusy = false;
      })
  }
}

</script>

<style lang="scss" scoped>
@import "@lazy/import/_step.scss";
</style>
